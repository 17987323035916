import React, {FC, ReactElement, useEffect, useState} from "react";
import {Card, Col, Nav, Row} from "react-bootstrap";
import './vorstand.scss';
import emma from './vorstand/BockFrank.jpg';
import ollo from './vorstand/OliverSpaniol.jpg';
import unknown from './vorstand/neu-temp/unknown.jpg';
import {api} from "../api";
import {Title} from "../Title";
import {TelNavLink} from "../TelNavLink";

interface ImageCardProps {
    image: string;
    title: string;
    name: string;
    since: number;
    mail: string;
    tel: number;
}

const ImageCard: FC<ImageCardProps> = ({image, title, name, since, mail, tel}): ReactElement => {
    return (
        <Card className="text-center shadow-lg rounded-0 coloration shadow">
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    <div>{name}</div>
                    <div><i className="p-1 bi-clock-history"/> {since}</div>
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <Nav>
                    <Nav.Link active href={"mailto:" + mail} className="mx-auto">
                        <i className="bi-at coloration" style={{fontSize: '26px'}}/>
                    </Nav.Link>
                    <TelNavLink tel={tel} fontSize={'24px'}/>
                </Nav>
            </Card.Footer>
        </Card>
    )
}

interface FBM {
    id: number;
    year: string;
    firstChairman: string;
    secondChairman: string;
    cashier: string;
    sportsDirector: string;
    youthLeader: string;
    secretary: string;
}

export const Vorstand: FC = (): ReactElement => {
    const [fbms, setFBMs] = useState<FBM[]>();

    useEffect(() => {
        api.get(`/boardMembers/formers`)
            .then((response) => {
                console.log(response);
                setFBMs(response.data);
            }).catch((e) => {
            throw e
        })
    }, [])

    return (
        <>
            <Title title={'Vorstand'}/>
            <Row className="justify-content-md-center">
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={emma} title={'1.Vorsitz'} name={'Michael Liebke'} since={2024}
                               mail={"vorstand@fc29.de"} tel={16097023252}/>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'2.Vorsitz'} name={'Meike Fricke'} since={2021}
                               mail={"vorstand@fc29.de"} tel={1716114878}/>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={ollo} title={'Finanzvorsitz'} name={'Oliver Spaniol'} since={2006}
                               mail={"vorstand@fc29.de"} tel={53469128260}/>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'Sportleitung'} name={'Ingo Zornhagen'} since={2021}
                               mail={"vorstand@fc29.de"} tel={1703225714}/>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'Jugendleitung'} name={'Domenik Weidner'} since={2022}
                               mail={"vorstand@fc29.de"} tel={0}/>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'Schriftführung'} name={'Sina Ratzka'} since={2024}
                               mail={"vorstand@fc29.de"} tel={0}/>
                </Col>
            </Row>
        </>
    );
}
