import React, {FC, ReactElement} from "react";
import {Card, Col, Row} from "react-bootstrap";

interface TitleProps {
    title: string;
}

export const Title: FC<TitleProps> = ({title}): ReactElement => {
    return (
        <Row className="justify-content-md-center">
            <Col>
                <Card className="text-center shadow-lg rounded-0 coloration">
                    <Card.Body>
                        <h4>{title}</h4>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}