import {FC, ReactElement} from "react";

import "./sponsors.css";

import hlf from "./pictures/Web_HLF_Logo_01.png";
import tankschutz from "./pictures/logo-tankschutz-klein-web.png";
import buderus from "./pictures/Screenshot 2024-03-24 022646.png";
import nordharz from "./pictures/tischlerteam_nordharz.jpg";
import concordia from "./pictures/concordia.png";

export const SponserStatic:FC = (): ReactElement => {
    return (
        <>
            <div className="logogrid">
                <a href="#"><img className="img-thumbnail" src={hlf}/></a>
                <a href="#"><img src={nordharz}/></a>
                <a href="#"><img src={buderus}/></a>
                <a href="#"><img src={hlf}/></a>
                <a href="#"><img src={nordharz}/></a>
                <a href="#"><img src={buderus}/></a>
                <a href="#"><img className="img-thumbnail" src={hlf}/></a>
                <a href="#"><img src={tankschutz}/></a>
                <a href="#"><img src={buderus}/></a>
                <a href="#"><img src={hlf}/></a>
                <a href="#"><img src={tankschutz}/></a>
                <a href="#"><img src={buderus}/></a>
            </div>
        </>
    )
}
export const SponsorsSlide: FC = (): ReactElement => {
    return (
        <>
            <div className="sliderBody">
                <div className="slider">
                    <div className="slide-track">
                        <div className="slide">
                            <img src={hlf} alt="hlf"/>
                        </div>
                        <div className="slide">
                            <img width={100} src={nordharz} alt="tankschutz"/>
                        </div>
                        <div className="slide">
                            <img src={buderus} alt="buderus"/>
                        </div>
                        <div className="slide">
                            <img src={hlf} alt="hlf"/>
                        </div>
                        <div className="slide">
                            <img width={100} src={tankschutz} alt="tankschutz"/>
                        </div>
                        <div className="slide">
                            <img width={100} src={concordia} alt="buderus"/>
                        </div>
                        <div className="slide">
                            <img src={hlf} alt="hlf"/>
                        </div>
                        <div className="slide">
                            <img width={100} src={nordharz} alt="tankschutz"/>
                        </div>
                        <div className="slide">
                            <img src={buderus} alt="buderus"/>
                        </div>
                        <div className="slide">
                            <img src={hlf} alt="hlf"/>
                        </div>
                        <div className="slide">
                            <img width={100} src={tankschutz} alt="tankschutz"/>
                        </div>
                        <div className="slide">
                            <img width={100} src={concordia} alt="buderus"/>
                        </div>
                        <div className="slide">
                            <img src={hlf} alt="hlf"/>
                        </div>
                        <div className="slide">
                            <img width={100} src={nordharz} alt="tankschutz"/>
                        </div>
                        <div className="slide">
                            <img src={buderus} alt="buderus"/>
                        </div>
                        <div className="slide">
                            <img src={hlf} alt="hlf"/>
                        </div>
                        <div className="slide">
                            <img width={100} src={tankschutz} alt="tankschutz"/>
                        </div>
                        <div className="slide">
                            <img width={100} src={concordia} alt="buderus"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}