import React, {FC, ReactElement, useState} from "react";
import {Container, Image, Nav, Navbar} from "react-bootstrap";
import {useMatch, useNavigate} from "react-router-dom";
import fcMan from '../FC_WAPPEN_Vektorgrafik.svg';
import {api} from "../api";

export const Header: FC = (): ReactElement => {
    const navigate = useNavigate();
    const match = useMatch({path: '/', end: true, caseSensitive: true});
    const [status, setStatus] = useState<boolean>(false);

    api.get("/health")
        .then((response) => {
            if (response.data === "Backend available" && !status) {
                setStatus(true);
            }
        }).catch((e) => {throw e});

    return (
        <Navbar>
            <Container fluid>
                <Navbar.Brand href="/" className="coloration p-0">
                    {!match && (
                        <Image
                            src={fcMan} height={40}
                            className="d-inline-block align-top"
                        />
                    )}
                </Navbar.Brand>
                <Nav>
                    <Nav.Link active={status} disabled={!status}>
                        <i className="bi-activity"/>
                    </Nav.Link>
                    <Nav.Link active href="https://www.instagram.com/fc29.de/">
                        <i className="bi-instagram coloration"/>
                    </Nav.Link>
                    <Nav.Link active href="https://www.facebook.com/fc29.de/">
                        <i className="bi-facebook coloration"/>
                    </Nav.Link>
                    <Nav.Link active href="mailto:info@fc.de">
                        <i className="bi-at coloration"/>
                    </Nav.Link>
                    <Nav.Link active href="https://www.google.com/maps/place/F.C.+Gro%C3%9F+D%C3%B6hren+von+1929+e.V./@51.9951638,10.4299716,17z/data=!4m14!1m7!3m6!1s0x47a543f014e36673:0x5fc25cb94dc7f088!2sF.C.+Gro%C3%9F+D%C3%B6hren+von+1929+e.V.!8m2!3d51.9951605!4d10.4325465!16s%2Fg%2F1tfrwhx_!3m5!1s0x47a543f014e36673:0x5fc25cb94dc7f088!8m2!3d51.9951605!4d10.4325465!16s%2Fg%2F1tfrwhx_">
                        <i className="bi-geo coloration"/>
                    </Nav.Link>
                    <Nav.Link active onClick={() => navigate("/impressum")}>
                        <i className="bi-info-lg coloration"/>
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}
