import React, {FC, ReactElement} from "react";
import {Card, Col, Nav, Row} from "react-bootstrap";
import {TelNavLink} from "../TelNavLink";
import emma from "../Vorstand/vorstand/BockFrank.jpg";

interface ImageCardProps {
    image: string;
    title: string;
    name: string;
    street: string;
    town: string
    mail: string;
    tel: number;
}

const ImageCard: FC<ImageCardProps> = ({image, title, name, street, town, mail, tel}): ReactElement => {
    return (
        <Card className="text-center shadow-lg rounded-0 coloration shadow">
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    <div>{name}</div>
                    <div>{street}</div>
                    <div>{town}</div>
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <Nav>
                    <Nav.Link active href={"mailto:" + mail} className="mx-auto">
                        <i className="bi-at coloration" style={{fontSize: '26px'}}/>
                    </Nav.Link>
                    <TelNavLink tel={tel} fontSize={'24px'}/>
                </Nav>
            </Card.Footer>
        </Card>
    )
}

export const Impressum: FC = (): ReactElement => {
    return (
        <>
            <Row>
                <Col xl={6} lg={6} md={6} sm={12} className="p-4">
                    <ImageCard image={emma} title={'Inhalte'} name={'FC Groß Döhren 1919 e.V.'} street={'Fortunaweg'}
                               town={'38704 Liebenburg'} mail={"info@fc29.de"} tel={15164037643}/>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} className="p-4">
                    <ImageCard image={emma} title={'Webmaster'} name={'Lukas Zornhagen'} street={'Brinkstraße 3'}
                               town={'38704 Liebenburg'} mail={"webmaster@fc29.de"} tel={15164037643}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="text-center shadow-lg rounded-0 coloration shadow">
                        <Card.Body>
                            <Card.Title>Haftungsausschluss</Card.Title>
                            <Card.Text className={"text-start"}>
                                <h6>1. Inhalt des Onlineangebotes</h6>
                                <div>Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
                                    Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche
                                    gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die
                                    durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die
                                    Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind
                                    grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich
                                    vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind
                                    freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der
                                    Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu
                                    ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig
                                    einzustellen.
                                </div>
                                <hr/>
                                <h6>2. Verweise und Links</h6>
                                <div>Bei direkten oder indirekten Verweisen auf fremde Webseiten ("Hyperlinks"), die
                                    außerhalb des Verantwortungsbereiches des Autors liegen, würde eine
                                    Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor
                                    von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die
                                    Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit
                                    ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu
                                    verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die
                                    Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat der Autor
                                    keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen
                                    Inhalten aller verlinkten /verknüpften Seiten, die nach der Linksetzung verändert
                                    wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes
                                    gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten
                                    Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen
                                    anderen Formen von Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich
                                    sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für
                                    Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen
                                    entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht
                                    derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
                                </div>
                                <hr/>
                                <h6>3. Urheber- und Kennzeichenrecht</h6>
                                <div>Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten
                                    Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst
                                    erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder
                                    auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen.
                                    Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten
                                    Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils
                                    gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen
                                    Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass
                                    Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für
                                    veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der
                                    Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente,
                                    Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist
                                    ohne ausdrückliche Zustimmung des Autors nicht gestattet.
                                </div>
                                <hr/>
                                <h6>4. Datenschutz</h6>
                                <div>Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher
                                    oder geschäftlicher Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt
                                    die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis.
                                    Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist - soweit technisch
                                    möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe
                                    anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des
                                    Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie
                                    Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch Dritte zur
                                    ÃƒÂœbersendung von nicht ausdrücklich angeforderten Informationen ist nicht
                                    gestattet. Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei
                                    Verstössen gegen dieses Verbot sind ausdrücklich vorbehalten.
                                </div>
                                <hr/>
                                <h6>5. Rechtswirksamkeit dieses Haftungsausschlusses</h6>
                                <div>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem
                                    aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen
                                    dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig
                                    entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und
                                    ihrer Gültigkeit davon unberührt.
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}