import React, {FC, ReactElement} from "react";
import {Card, Col, Image, Row} from "react-bootstrap";
import './overview.css';
import logo from "../FC_WAPPEN_Vektorgrafik.svg";
import erste from "./manni21.jpeg";
import damen from "./mannschaftsfoto.jpg";
import zweite from "./mannschaftsfotoZWOTE.jpg";
import senioren from "./mannschaftsfoto.jpeg";
import {useNavigate} from "react-router-dom";
import {Vorstand} from "../Vorstand";
import {Mannschaften} from "../Mannschaften";
import {SponsorsSlide, SponserStatic} from "../Sponsors";

interface ImageCardProps {
    image: string;
    title: string;
    league: string;
    path: string;
}

const ImageCard: FC<ImageCardProps> = ({image, title, league, path}) => {
    const navigate = useNavigate();
    return (
        <Card className="shadow-lg rounded-0 coloration clickable" onClick={() => navigate(path)}>
            <Card.Img variant="top" src={image}/>
            <Card.Body style={{textAlign: 'center'}}>
                <Card.Title>{title}</Card.Title>
                <Card.Subtitle className="mb-2 coloration">{league}</Card.Subtitle>
            </Card.Body>
        </Card>
    )
}

interface LabelCardProps {
    title: string;
    path: string;
}

const LabelCard: FC<LabelCardProps> = ({title, path}): ReactElement => {
    const navigate = useNavigate();
    return (
        <Card className="shadow-lg rounded-0 coloration clickable" onClick={() => navigate(path)}>
            <Card.Body style={{textAlign: 'center'}}>
                <Card.Title>{title}</Card.Title>
            </Card.Body>
        </Card>
    )
}

export const Overview: FC = (): ReactElement => {
    return (
        <>
            <Row className="justify-content-md-center">
                <Col md={4}>
                    <Image width={300} className="mx-auto d-block" src={logo} alt="Logo"/>
                </Col>
            </Row>
            <Vorstand/>
            <Mannschaften/>
            <SponsorsSlide/>
        </>
    );
}

const OverviewOldOnes: FC = (): ReactElement => {
    return (
        <>
            <Row className="justify-content-md-center">
                <Col md={4}>
                    <Image width={300} className="mx-auto d-block" src={logo} alt="Logo"/>
                </Col>
            </Row>
            <Row>
                <Col md={3} className="p-4">
                    <ImageCard image={damen} title="DAMEN" league="Bezirksliga" path="/fussball/team/damen"/>
                </Col>
                <Col md={3} className="p-4">
                    <ImageCard image={erste} title="HERREN" league="Nordharzliga" path="/fussball/team/herren"/>
                </Col>
                <Col md={3} className="p-4">
                    <ImageCard image={zweite} title="DARTS" league=" - " path="/darts/team"/>
                </Col>
                <Col md={3} className="p-4">
                    <ImageCard image={senioren} title="SENIOREN" league="Nordharzliga" path="fussball/team/senioren"/>
                </Col>
                <Col md={3} className="p-4">
                    <LabelCard title="VORSTAND" path="/vorstand"/>
                </Col>
                <Col md={3} className="p-4">
                    <LabelCard title="GYMNASTIK" path="/gymnastik"/>
                </Col>
                <Col md={3} className="p-4">
                    <LabelCard title="CHRONIK" path="/chronik"/>
                </Col>
                <Col md={3} className="p-4">
                    <LabelCard title="GALERIE" path="/galerie"/>
                </Col>
            </Row>
        </>
    );
}
const OvervieOldOnes: FC = (): ReactElement => {
    return (
        <>
            <Row className="justify-content-md-center">
                <Col md={4}>
                    <Image width={300} className="mx-auto d-block" src={logo} alt="Logo"/>
                </Col>
            </Row>
            <Row>
                <Col md={3} className="p-4">
                    <ImageCard image={damen} title="DAMEN" league="Bezirksliga" path="/fussball/team/damen"/>
                </Col>
                <Col md={3} className="p-4">
                    <ImageCard image={erste} title="HERREN" league="Nordharzliga" path="/fussball/team/herren"/>
                </Col>
                <Col md={3} className="p-4">
                    <ImageCard image={zweite} title="DARTS" league=" - " path="/darts/team"/>
                </Col>
                <Col md={3} className="p-4">
                    <ImageCard image={senioren} title="SENIOREN" league="Nordharzliga" path="fussball/team/senioren"/>
                </Col>
                <Col md={3} className="p-4">
                    <LabelCard title="VORSTAND" path="/vorstand"/>
                </Col>
                <Col md={3} className="p-4">
                    <LabelCard title="GYMNASTIK" path="/gymnastik"/>
                </Col>
                <Col md={3} className="p-4">
                    <LabelCard title="CHRONIK" path="/chronik"/>
                </Col>
                <Col md={3} className="p-4">
                    <LabelCard title="GALERIE" path="/galerie"/>
                </Col>
            </Row>
        </>
    );
}
