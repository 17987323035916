import React, {FC, ReactElement} from "react";
import {Title} from "../Title";
import {Card, Col, Nav, Row} from "react-bootstrap";
import {TelNavLink} from "../TelNavLink";
import unknown from "../Vorstand/vorstand/neu-temp/unknown.jpg";

interface ImageCardProps {
    image: string;
    title: string;
    division: string;
    instructor: string;
    mail: string;
    tel: number;
    instagram: string;
    facebook: string;
    statistics: string;
}

const ImageCard: FC<ImageCardProps> = ({
                                           image,
                                           title,
                                           division,
                                           instructor,
                                           mail,
                                           tel,
                                           instagram,
                                           facebook,
                                           statistics
                                       }): ReactElement => {
    return (
        <Card className="text-center shadow-lg rounded-0 coloration shadow">
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    <div>{division}</div>
                    <div><i className="p-1 bi-person-fill"/> {instructor}</div>
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <Nav>
                    <Nav.Link active href={statistics} className="mx-auto">
                        <i className="bi-table coloration" style={{fontSize: '20px'}}/>
                    </Nav.Link>
                    <Nav.Link active href={"mailto:" + mail} className="mx-auto">
                        <i className="bi-at coloration" style={{fontSize: '20px'}}/>
                    </Nav.Link>
                    <TelNavLink tel={tel} fontSize={'18px'}/>
                    <Nav.Link active href={instagram} className="mx-auto">
                        <i className="bi-instagram coloration" style={{fontSize: '20px'}}/>
                    </Nav.Link>
                    <Nav.Link active href={facebook} className="mx-auto">
                        <i className="bi-facebook coloration" style={{fontSize: '20px'}}/>
                    </Nav.Link>
                </Nav>
            </Card.Footer>
        </Card>
    )
}

export const Mannschaften: FC = (): ReactElement => {
    return (
        <>
            <Title title={'Teams - Fußball'}/>
            <Row className="justify-content-md-center">
                <Col xl={3} lg={4} md={6} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'Frauen'} division={'Bezirksliga'}
                               instructor={'Susanne Steinke'} mail={"vorstand+damen@fc29.de"} tel={17661795138}
                               instagram={'https://www.instagram.com/fc_gr._doehren_damen/'}
                               facebook={'https://www.facebook.com/SgKlMahnerDamen/'}
                               statistics={'https://www.fussball.de/mannschaft/fc-gross-doehren-fc-gross-doehren-niedersachsen/-/saison/2324/team-id/020SNOALA8000000VS548984VSKC9ER0#!/'}/>
                </Col>
                <Col xl={3} lg={4} md={6} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'I. Herren'} division={'Nordharzliga'}
                               instructor={'Dennis Koch'} mail={"vorstand+ersteHerren@fc29.de"} tel={0}
                               instagram={'https://www.instagram.com/fc29.de/'}
                               facebook={'https://www.facebook.com/fc29.de/'}
                               statistics={'https://www.fussball.de/mannschaft/fc-gross-doehren-fc-gross-doehren-niedersachsen/-/saison/2324/team-id/011MID1R9O000000VTVG0001VTR8C1K7#!/'}/>
                </Col>
                <Col xl={3} lg={4} md={6} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'II. Herren'} division={'4.Nordharzklasse'}
                               instructor={'Timo Kubitschke'} mail={"vorstand+zweiteHerren@fc29.de"} tel={0}
                               instagram={'https://www.instagram.com/fc_gross_doehren_ultras/'}
                               facebook={'https://www.facebook.com/fc29.de/'}
                               statistics={'https://www.fussball.de/mannschaft/fc-gross-doehren-ii-fc-gross-doehren-niedersachsen/-/saison/2324/team-id/019TFOH864000000VV0AG80NVVK3URHB#!/'}/>
                </Col>
                <Col xl={3} lg={4} md={6} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'Ü50 Senioren'} division={'Nordharzklasse'}
                               instructor={'Michael Liebke'} mail={"vorstand+senioren@fc29.de"} tel={16097023252}
                               instagram={'https://www.instagram.com/fc_gross_doehren_ultras/'}
                               facebook={'https://www.facebook.com/fc29.de/'}
                               statistics={'https://www.fussball.de/mannschaft/sg-grube-fortuna-fc-gross-doehren-niedersachsen/-/saison/2324/team-id/02IC9IKE7S000000VS5489B2VU2I8R4H#!/'}/>
                </Col>
            </Row>
            <Title title={'Teams - Darts - Red White 29'}/>
            <Row className="justify-content-md-center">
                <Col xl={3} lg={4} md={6} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'A-Mannschaft'} division={'Kreisliga'}
                               instructor={'Kevin Michalsky'} mail={"vorstand+darten@fc29.de"} tel={1709372610}
                               instagram={'https://www.instagram.com/red_white_29_doehren/'}
                               facebook={'https://www.facebook.com/groups/2087567308094112'}
                               statistics={'https://ndv.2k-dart-software.de/index.php/de/component/dartliga/index.php?option=com_dartliga&controller=showligagameplan&layout=showdashboard&filVbKey=6&filCompKey=1&filSaiKey=126&filVbsubKey=3&filStaffKey=1055&filStaffFsGrpdataKey=0#'}/>
                </Col>
                <Col xl={3} lg={4} md={6} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'B-Mannschaft'} division={'Kreisklasse'}
                               instructor={'Ludwig Aures'} mail={"vorstand+darten@fc29.de"}
                               tel={16096783952} instagram={'https://www.instagram.com/red_white_29_doehren/'}
                               facebook={'https://www.facebook.com/groups/2087567308094112'}
                               statistics={'https://ndv.2k-dart-software.de/index.php/de/component/dartliga/index.php?option=com_dartliga&controller=showligagameplan&layout=showdashboard&filVbKey=6&filCompKey=1&filSaiKey=126&filVbsubKey=3&filStaffKey=1063&filStaffFsGrpdataKey=0#'}/>
                </Col>
                <Col xl={3} lg={4} md={6} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'C-Mannschaft'} division={'Kreisklasse'}
                               instructor={'Thorsten Bergmeier'} mail={"vorstand+darten@fc29.de"}
                               tel={16096783952} instagram={'https://www.instagram.com/red_white_29_doehren/'}
                               facebook={'https://www.facebook.com/groups/2087567308094112'}
                               statistics={'https://ndv.2k-dart-software.de/index.php/de/component/dartliga/index.php?option=com_dartliga&controller=showligagameplan&layout=showdashboard&filVbKey=6&filCompKey=1&filSaiKey=126&filVbsubKey=3&filStaffKey=1063&filStaffFsGrpdataKey=0#'}/>
                </Col>
            </Row>
        </>
    )
}