import React, {FC, ReactElement} from "react";
import {Nav} from "react-bootstrap";

export const TelNavLink: FC<{ tel: number; fontSize: string }> = ({tel, fontSize}): ReactElement => {
    if (tel === 0) {
        return (
            <Nav.Link disabled className="mx-auto">
                <i className="bi-telephone-inbound" style={{fontSize}}/>
            </Nav.Link>
        )
    }
    return (
        <Nav.Link active href={"tel:+49" + tel} className="mx-auto">
            <i className="bi-telephone-inbound coloration" style={{fontSize}}/>
        </Nav.Link>
    )
}